import { useAtom, useSetAtom } from "jotai";
import { nanoid } from "nanoid";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { setHideModalAtomFamily, showModalAtomFamily } from "./state";
import "./BasicModal.module.scss";
const defaultOnConfirm = () => true;
const BasicModal = ({ title, id, children, footerChildren = null, footerCloseButtonHidden = false, headerCloseButtonHidden = false, 
// when the confirm button is shown, the close button becomes cancel button, but it still has the same event handler
closeButtonText, confirmButtonText, backdrop = true, keyboard = false, 
// this is fired when user presses ESC on their keyboard, X button, close button, cancel button or a backdrop
// NOT fired when OK button is clicked
onHide, size = "lg", onConfirm = defaultOnConfirm, fullscreen = false, ...modalProps }) => {
    const { t } = useTranslation();
    // We want to be able to control when children are mounted (and only then load relevant data),
    // but also unmount modal itself after the hide transition is completed (onExited).
    const [show, setShow] = useAtom(showModalAtomFamily(id));
    const [hide, setHide] = useAtom(setHideModalAtomFamily(id));
    const [exiting, setExiting] = useState(false);
    const confirmButtonShown = onConfirm !== defaultOnConfirm;
    const setClose = useCallback(() => {
        setExiting(true);
        onHide?.();
    }, [onHide, setExiting]);
    const onConfirmClick = useCallback(async () => {
        try {
            const canClose = await onConfirm?.();
            if (canClose) {
                setExiting(true);
            }
        }
        catch {
            /* empty */
        }
    }, [onConfirm, setExiting]);
    useEffect(() => {
        // Allow triggering closing the modal programatically.
        // Difference with setting `show` to `false` is that it will nicely transition out.
        if (hide) {
            const closeButton = document.querySelector(`#${CSS.escape(id)} .close`);
            if (closeButton)
                closeButton?.click();
            else
                setShow(false);
        }
    }, [id, hide, setShow]);
    return (React.createElement(Modal, { ...modalProps, id: id, show: show && !exiting, onHide: setClose, centered: true, size: size === "md" ? undefined : size, backdrop: backdrop, keyboard: keyboard, onExited: (node) => {
            modalProps.onExited?.(node);
            setShow(false);
            setExiting(false);
            setHide(false);
        }, dialogClassName: fullscreen ? "modal-content--full" : undefined },
        React.createElement(Modal.Header, { closeButton: !headerCloseButtonHidden },
            React.createElement(Modal.Title, null, title)),
        React.createElement(Modal.Body, null, children),
        React.createElement(Modal.Footer, null,
            footerChildren,
            !footerCloseButtonHidden && (React.createElement(Button, { variant: confirmButtonShown ? "light" : "primary", onClick: setClose }, closeButtonText ?? t(confirmButtonShown ? "Cancel" : "Close"))),
            confirmButtonShown && (React.createElement(Button, { variant: "primary", onClick: onConfirmClick }, confirmButtonText ?? t("OK"))))));
};
function useBasicModal() {
    const idRef = useRef(nanoid());
    const [show, setShow] = useAtom(showModalAtomFamily(idRef.current));
    const setHide = useSetAtom(setHideModalAtomFamily(idRef.current));
    useEffect(() => {
        const id = idRef.current;
        return () => {
            showModalAtomFamily.remove(id);
            setHideModalAtomFamily.remove(id);
        };
    }, []);
    const Modal = useMemo(() => function Modal(props) {
        return React.createElement(BasicModal, { ...props, id: idRef.current });
    }, []);
    return useMemo(() => ({
        show,
        Modal,
        toggle(state) {
            if (state !== undefined && !state) {
                // Hide modal, but wait with unmount until transition is over
                setHide(true);
            }
            else {
                setHide(false);
                setShow((currentShow) => (state !== undefined ? state : !currentShow));
            }
        },
        open() {
            setHide(false);
            setShow(true);
        },
        close() {
            setHide(true);
        },
    }), [Modal, setHide, setShow, show]);
}
export default useBasicModal;
