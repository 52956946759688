import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Spinner from "@ce/components/shared/spinner/Spinner";
/**
 * Login component that has to be implemented fully when going react with JWT
 * For now it will do a hard refresh to the .net page.
 */
export function Login() {
    const location = useLocation();
    useEffect(() => {
        const url = location.state.pathname + location.state.search;
        window.location.href = `/login?returnUrl=${encodeURIComponent(url)}`;
    }, [location.state]);
    return React.createElement(Spinner, null);
}
