import { registerLocale as registerLocaleCountries } from "i18n-iso-countries";
import i18next, { use } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import Cookie from "js-cookie";
import { initReactI18next } from "react-i18next";
import languages from "@ce/languages";
import { formatPercentage, formatPrice } from "./components/shared/formatters";
import { getLocale, setLocale } from "./helpers/dateTime";
const COOKIE_NAME_CE_CULTURE = "CE-CULTURE";
export const supportedLanguages = languages.languages.toSorted();
export const i18nFormatter = (value, format = "", lng = "en") => {
    /// <summary>
    /// Format a certain value, such as  price or percentage.
    /// Usage: {t("whatever", `{{value, price, ${myCurrencyCode}}}`, {
    //                                 value: myValue,
    //                             })}
    /// </summary>
    const [formatName, ...additionalValues] = format.split(",").map((v) => v.trim());
    switch (formatName) {
        case "percent": {
            const fraction = additionalValues.length > 0 && /\d+/.test(additionalValues[0])
                ? Number.parseInt(additionalValues[0])
                : undefined;
            return formatPercentage(value, lng, {
                minimumFractionDigits: fraction,
                maximumFractionDigits: fraction,
            });
        }
        case "price":
            return formatPrice(value, lng, {
                currency: additionalValues.at(0) ?? "EUR",
            });
        case "currencyCode":
            // show currency code only
            return formatPrice(0, lng, {
                currency: additionalValues.at(0) ?? "EUR",
            }).replace("0", "");
    }
    return value;
};
function getCookieLanguage(lookupCookie) {
    const ceCulture = Cookie.get(lookupCookie);
    const matches = ceCulture?.match(/c=(.*)\|uic=(.*)/);
    let lng;
    if (matches && matches.length > 0) {
        lng = matches[1] ?? matches[2];
    }
    return lng;
}
// eslint-disable-next-line unicorn/prefer-top-level-await
registerCountries();
function cacheUserLanguage(lng, options) {
    const oldLng = options.htmlTag?.lang;
    // Set cookie for .NET
    Cookie.set(options.lookupCookie ?? "", `c=${lng}|uic=${lng}`);
    if (options.htmlTag)
        options.htmlTag.lang = lng;
    // Refresh page when language is changed and cookie stored. Only needed because of AngularJS and .NET
    // Once menu and service headers are migrated to react, this can be removed.
    if (oldLng && oldLng !== lng)
        location.reload();
}
const languageDetector = new LanguageDetector();
languageDetector.addDetector({
    name: "ce-cookie",
    lookup(options) {
        return getCookieLanguage(options.lookupCookie ?? COOKIE_NAME_CE_CULTURE) ?? "en";
    },
    cacheUserLanguage,
});
languageDetector.addDetector({
    name: "html-lang",
    lookup(options) {
        return options.htmlTag?.lang;
    },
    cacheUserLanguage,
});
use(Backend)
    .use(languageDetector)
    .use(initReactI18next)
    .init({
    returnNull: false,
    supportedLngs: supportedLanguages,
    fallbackLng: "en",
    interpolation: {
        format: i18nFormatter,
        escapeValue: false,
    },
    detection: {
        order: ["html-lang", "ce-cookie"],
        caches: ["html-lang"],
        lookupCookie: COOKIE_NAME_CE_CULTURE,
        htmlTag: document.documentElement,
    },
})
    // eslint-disable-next-line unicorn/prefer-top-level-await
    .then(async () => {
    // Add handlers that depend on language as soon as it's loaded
});
i18next.on("languageChanged", async (language) => {
    const tasks = [import("react-datepicker"), setLocale(language)];
    const [{ registerLocale: registerLocaleDatepicker }] = await Promise.all(tasks);
    registerLocaleDatepicker(language, getLocale(language));
});
async function registerCountries() {
    // Register countries async.
    const tasks = supportedLanguages.map((code) => import(`i18n-iso-countries/langs/${code}.json`));
    const countryLocales = await Promise.all(tasks);
    for (const { default: loc } of countryLocales) {
        registerLocaleCountries(loc);
    }
}
export { default } from "i18next";
