import React, { Suspense, lazy } from "react";
import ErrorBoundary from "@ce/components/shared/ErrorBoundary";
import { Portal } from "../Portal";
/**
 * Features that don't have a dedicated page, but live inside another route as part of that page or a 'widget'.
 */
const modules = {
    channelpricing: true,
    channelsetupportal: true,
    productadditionalinformation: true,
    listedproductserrors: true,
    listedproductschanneloffers: true,
    listedproductserrorsmodal: true,
    listedproductsoutofsync: true,
    listedproductsstatistics: true,
    notificationsalert: true,
    notificationsdropdown: true,
    orderdetaildocuments: true,
    pricemutationhistory: true,
    productcustomvatrates: true,
    productdetailsfreeze: true,
    productfruitswidget: true,
    productstockallocations: true,
    productstockcalculationmodal: true,
    repricercompetitorhistory: true,
    vouchers: true,
    zendeskwidget: true,
};
export const modulePortals = Object.entries(modules).map(([moduleName, enabled]) => {
    if (!enabled)
        return null;
    const Component = lazy(() => import(`../../components/${moduleName}/index`));
    return (React.createElement(ErrorBoundary, { key: moduleName },
        React.createElement(Suspense, null,
            React.createElement(Portal, { rootId: `react-app-${moduleName}` }, (props) => React.createElement(Component, { ...props })))));
});
