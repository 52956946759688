import React, { useState } from "react";
import { Button, Toast } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useBasicModal from "@ce/components/shared/modals/BasicModal";
import { NotificationType } from "../../types/notification.interfaces";
export const Notification = ({ title, message, learnMoreContent, type = NotificationType.Neutral, delay = 20000, autohide = true, showCloseButton = true, }) => {
    const [showToast, setShowToast] = useState(true);
    const { open, Modal } = useBasicModal();
    const { t } = useTranslation();
    const handleOnClose = () => {
        if (showCloseButton)
            setShowToast(false);
    };
    return (React.createElement(Toast, { show: showToast, onClose: handleOnClose, autohide: autohide, delay: delay, className: NotificationType[type].toLowerCase() },
        React.createElement(Toast.Header, { closeButton: showCloseButton },
            React.createElement("strong", { className: "mr-auto" }, title)),
        React.createElement(Toast.Body, { className: "text-break" }, message),
        learnMoreContent && (React.createElement(React.Fragment, null,
            React.createElement(Toast.Body, null,
                React.createElement(Button, { variant: "link", className: `p-0 ${type === NotificationType.Neutral ? "text-black" : "text-white"}`, onClick: open }, t("ReadMore"))),
            React.createElement(Modal, { title: t("ReadMore") }, learnMoreContent)))));
};
