import React from "react";
import { useTranslation } from "react-i18next";
export const Toolbar = () => {
    const { t } = useTranslation();
    return (React.createElement("div", { className: "btn-toolbar", role: "toolbar" },
        React.createElement("a", { href: "/", className: "btn btn-primary mr-2", role: "button" },
            React.createElement("i", { className: "fa fa-fw fa-lg fa-home" }),
            " ",
            t("HomePage")),
        React.createElement("a", { href: "https://support.channelengine.com", target: "_blank", rel: "noreferrer noopener", className: "btn btn-outline-primary", role: "button" },
            React.createElement("i", { className: "fa fa-question-circle" }),
            " ",
            t("HelpCenter"))));
};
