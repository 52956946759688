import React, { createContext, useContext, useMemo, useState } from "react";
import { NotificationContainer } from "@ce/global/Notifications/NotificationContainer";
import { NotificationType } from "@ce/types/notification.interfaces";
import { Portal } from "../Portal";
export const NotificationContext = createContext({
    addNotification: () => null,
});
export const NotificationContextProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);
    const addNotification = (notification) => {
        setNotifications((oldNotifications) => [...oldNotifications, notification]);
    };
    const contextValue = useMemo(() => ({
        addNotification,
    }), []);
    return (React.createElement(NotificationContext.Provider, { value: contextValue },
        children,
        React.createElement(Portal, { rootId: "react-portal-toasts" },
            React.createElement(NotificationContainer, { notifications: notifications }))));
};
export const useNotifications = () => {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error("This component must be used within a <NotificationContextProvider>.");
    }
    const { addNotification } = context;
    return useMemo(() => ({
        add: addNotification,
        addSuccess(notification) {
            addNotification({
                ...notification,
                type: NotificationType.Success,
            });
        },
        addError(notification) {
            addNotification({
                ...notification,
                type: NotificationType.Error,
            });
        },
    }), [addNotification]);
};
