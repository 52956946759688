import React from "react";
import { Spinner as BootstrapSpinner, } from "react-bootstrap";
import { useTranslation } from "react-i18next";
const Spinner = ({ style, className = "", ...spinnerProps }) => {
    const { t } = useTranslation();
    return (React.createElement("div", { className: `d-flex justify-content-center ${className}`, style: style },
        React.createElement(BootstrapSpinner, { ...spinnerProps, animation: "border", role: "status" },
            React.createElement("span", { className: "sr-only" }, t("Loading")))));
};
export default Spinner;
