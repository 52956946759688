import { withProfiler } from "@sentry/react";
import "i18n";
import React, { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";
import ErrorBoundary from "@ce/components/shared/ErrorBoundary";
import { MainProviderWrapper } from "@ce/global/MainProvider";
import { Portal } from "@ce/global/Portal";
import { hasSentryEnvironmentDefinitions } from "@ce/helpers/sentry";
import "main.scss";
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const node = document.querySelector("#react-app-login");
const root = createRoot(node);
if (hasSentryEnvironmentDefinitions()) {
    const App = withProfiler(LoginApp);
    root.render(React.createElement(App, null));
}
else {
    root.render(React.createElement(LoginApp, null));
}
/**
 * Features that don't have a dedicated page, but live inside another route as part of that page or a 'widget'.
 */
const modules = {
    g2reviewwidget: true,
};
export const modulePortals = Object.entries(modules).map(([moduleName, enabled]) => {
    if (!enabled)
        return null;
    const Component = lazy(() => import(`../../components/${moduleName}/index`));
    return (React.createElement(ErrorBoundary, { key: moduleName },
        React.createElement(Suspense, null,
            React.createElement(Portal, { rootId: `react-app-${moduleName}` }, (props) => React.createElement(Component, { ...props })))));
});
function LoginApp() {
    return React.createElement(MainProviderWrapper, null, modulePortals);
}
