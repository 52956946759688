import axios from "axios";
export const ceApi = axios.create({
    baseURL: "/",
    withCredentials: true,
    headers: {
        "X-Requested-With": "XMLHttpRequest",
    },
    validateStatus: (status) => status >= 200 && status < 300,
});
ceApi.interceptors.response.use((response) => response, (error) => Promise.reject(error));
ceApi.interceptors.request.use((config) => {
    if (!config.headers?.has("X-CSRF-TOKEN")) {
        const token = getRequestToken();
        if (token != null) {
            config.headers.set("X-CSRF-TOKEN", token);
        }
        else {
            throw new Error("Retrieving Antiforgery token failed.");
        }
    }
    return config;
});
export const getRequestToken = () => document.querySelector("input[name='__RequestVerificationToken']")
    ?.value ?? null;
