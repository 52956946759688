import { isFunction } from "lodash-es";
import { useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
export function Portal({ children, rootId }) {
    const [rootNode, setRootNode] = useState(null);
    // Parse props from data-props attribute on portal container.
    const props = useMemo(() => {
        if (!rootNode)
            return null;
        return JSON.parse(rootNode.dataset.props ?? "{}");
    }, [rootNode]);
    useEffect(() => {
        const node = document.querySelector(`#${rootId}`);
        setRootNode(node);
    }, [rootId]);
    return rootNode
        ? createPortal(isFunction(children) ? children(props) : children, rootNode, rootId)
        : null;
}
