import { hashKey } from "@tanstack/react-query";
import { memoize, omit } from "lodash-es";
export function translatePrice(t, price, currencyCode) {
    return price == null
        ? null
        : t("FormatPriceAmount", `{{value, price, ${currencyCode}}}`, {
            value: price,
        });
}
export function translatePercentage(t, perc, fraction = 1) {
    return t("FormatPercentage", `{{value, percent, ${fraction}}}`, {
        value: perc,
    });
}
// Creation of Intl.NumberFormat is heavy on performance.
// We can memoize this factory to reuse an instance per culture.
// Reuse react-query's method to deterministically hash cache key.
const createNumberFormatter = memoize(function getNumberFormat(locales, options) {
    return Intl.NumberFormat(locales, options);
}, (locales, options = {}) => hashKey([locales, options]));
const defaultOptionsNumber = {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
};
export function formatNumber(number, locales, options = {}) {
    const nf = createNumberFormatter(locales, { ...defaultOptionsNumber, ...options });
    return nf.format(number);
}
const defaultOptionsPrice = {
    style: "currency",
};
export function formatPrice(price, locales, options) {
    if (!options.currency)
        return formatNumber(price, locales, omit(options, "currency"));
    const nf = createNumberFormatter(locales, { ...defaultOptionsPrice, ...options });
    return nf.format(price);
}
const defaultOptionsPercentage = {
    style: "percent",
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
};
export function formatPercentage(percentage, locales, options = {}) {
    const nf = createNumberFormatter(locales, {
        ...defaultOptionsPercentage,
        ...options,
    });
    return nf.format(percentage);
}
