import { atom, useAtomValue, useSetAtom } from "jotai";
import { noop } from "lodash-es";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
export const ConfirmationModalLoader = () => {
    const props = useAtomValue(confirmModalPropsAtom);
    return props ? React.createElement(ConfirmationModal, { ...props }) : null;
};
const ConfirmationModal = ({ callback = noop, onExit = noop, title, body, confirmText, cancelText, size, confirmVariant = "primary", }) => {
    const { t } = useTranslation();
    const [show, setShow] = useState(true);
    const handleConfirm = () => {
        callback(true);
        setShow(false);
    };
    const handleCancel = () => {
        callback(false);
        setShow(false);
    };
    return (React.createElement(Modal, { show: show, size: size, onHide: handleCancel, centered: true, onExit: onExit },
        React.createElement(Modal.Header, { closeButton: true },
            React.createElement(Modal.Title, { id: "example-custom-modal-styling-title" }, title ?? t("AreYouSure"))),
        body && (React.createElement(Modal.Body, null,
            React.createElement("div", null, body))),
        React.createElement(Modal.Footer, null,
            React.createElement(Button, { variant: "outline-secondary", onClick: handleCancel }, cancelText ?? t("No")),
            React.createElement(Button, { onClick: handleConfirm, variant: confirmVariant }, confirmText ?? t("Yes")))));
};
const confirmModalPropsAtom = atom(null);
function useConfirmModal() {
    const setConfirmModalProps = useSetAtom(confirmModalPropsAtom);
    return {
        show(props = {}) {
            return new Promise((resolve) => {
                setConfirmModalProps({
                    ...props,
                    callback: resolve,
                    onExit: () => setConfirmModalProps(null),
                });
            });
        },
    };
}
export default useConfirmModal;
