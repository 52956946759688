import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { isEmpty } from "lodash-es";
export var SentryEnvironment;
(function (SentryEnvironment) {
    SentryEnvironment[SentryEnvironment["Production"] = 0] = "Production";
    SentryEnvironment[SentryEnvironment["Acceptance"] = 1] = "Acceptance";
    SentryEnvironment[SentryEnvironment["Test"] = 2] = "Test";
    SentryEnvironment[SentryEnvironment["Development"] = 3] = "Development";
})(SentryEnvironment || (SentryEnvironment = {}));
const sentryEnvironments = [
    { type: SentryEnvironment.Development, pattern: /development$/i },
    { type: SentryEnvironment.Test, pattern: /test$/i },
    { type: SentryEnvironment.Acceptance, pattern: /acceptance$/i },
    { type: SentryEnvironment.Production, pattern: /production$/i },
];
export function hasSentryEnvironmentDefinitions() {
    const hasEmptyDefinitions = isEmpty(process.env.SENTRY_DSN?.toString()) ||
        isEmpty(process.env.SENTRY_ORG?.toString()) ||
        isEmpty(process.env.SENTRY_PROJECT?.toString()) ||
        isEmpty(process.env.SENTRY_ENVIRONMENT?.toString());
    return !hasEmptyDefinitions;
}
export function getSentryEnvironment(fallback = SentryEnvironment.Development) {
    const buildEnvironment = process.env.SENTRY_ENVIRONMENT ?? "";
    return sentryEnvironments.find((env) => env.pattern.test(buildEnvironment))?.type ?? fallback;
}
if (hasSentryEnvironmentDefinitions()) {
    Sentry.init({
        dsn: process.env.SENTRY_DSN?.toString(),
        release: process.env.BUILD_NUMBER?.toString(),
        environment: process.env.SENTRY_ENVIRONMENT?.toString(),
        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1,
        integrations: [
            new BrowserTracing(),
            ...(Boolean(process.env.SENTRY_REPLAY) === true ? [new Sentry.Replay()] : []),
        ],
        // We recommend adjusting this value in production, or using tracesSampler for finer control
        tracesSampleRate: getSentryEnvironment() === SentryEnvironment.Production ? 0.1 : 1,
        enabled: hasSentryEnvironmentDefinitions(),
    });
    Sentry.setTag("framework", "React");
}
