import React from "react";
import { Alert } from "react-bootstrap";
export function RequestInfo({ showRequestId, requestId, showLogId, logId }) {
    if (!showRequestId && !showLogId)
        return null;
    return (React.createElement(Alert, { variant: "secondary" },
        showRequestId && (React.createElement(React.Fragment, null,
            React.createElement("span", null,
                "Request ID: ",
                React.createElement("samp", null, requestId)),
            React.createElement("br", null))),
        showLogId && (React.createElement(React.Fragment, null,
            React.createElement("span", null,
                "Log ID: ",
                React.createElement("samp", null, logId))))));
}
