import { useUpdateEffect } from "@react-hookz/web";
import { atom, useSetAtom } from "jotai";
import React, { Suspense } from "react";
import { Outlet, Route, ScrollRestoration, createBrowserRouter, createRoutesFromElements, useLocation, useNavigation, } from "react-router-dom";
import { ErrorBoundaryAlert } from "@ce/components/shared/ErrorBoundary";
import { LoadingBar } from "@ce/components/shared/loaders/LoadingBar";
import { ConfirmationModalLoader } from "@ce/components/shared/modals/Confirmation";
import Spinner from "@ce/components/shared/spinner/Spinner";
import { Login } from "./Login";
import { NotFound } from "./errors/NotFound";
import routesInfo from "./routes";
export const previousLocationAtom = atom(null);
const RouteRoot = () => {
    const navigation = useNavigation();
    const location = useLocation();
    const setPreviousLocation = useSetAtom(previousLocationAtom);
    useUpdateEffect(() => {
        setPreviousLocation(location);
    }, [location, setPreviousLocation]);
    return (React.createElement(Suspense, { fallback: React.createElement(Spinner, null) },
        navigation.state === "loading" && React.createElement(LoadingBar, null),
        React.createElement(ScrollRestoration, null),
        React.createElement(Outlet, null),
        React.createElement(ConfirmationModalLoader, null)));
};
const routes = (React.createElement(Route, { element: React.createElement(RouteRoot, null), errorElement: React.createElement(ErrorBoundaryAlert, null) },
    ...routesInfo,
    React.createElement(Route, { path: "/unauthorized", element: "<Unauthorized todo />" }),
    React.createElement(Route, { path: "/login", element: React.createElement(Login, null) }),
    React.createElement(Route, { path: "*", element: React.createElement(NotFound, null) })));
export const router = createBrowserRouter(createRoutesFromElements(routes));
