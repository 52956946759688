import { useQueryClient } from "@tanstack/react-query";
import { Provider as JotaiProvider } from "jotai";
import { queryClientAtom } from "jotai-tanstack-query";
import { useHydrateAtoms } from "jotai/utils";
import React from "react";
import { RouterProvider } from "react-router-dom";
import { NotificationContextProvider } from "./Notifications/NotificationContext";
import ReactQueryWrapper from "./ReactQuery";
import { modulePortals } from "./routing/portals";
import { router } from "./routing/router";
export const MainProviderWrapper = ({ children }) => (
// <React.StrictMode>
React.createElement(NotificationContextProvider, null,
    React.createElement(ReactQueryWrapper, null,
        React.createElement(JotaiProvider, null,
            React.createElement(HydrateAtoms, null, children))))
// </React.StrictMode>
);
export const MainProvider = () => {
    return (React.createElement(MainProviderWrapper, null,
        React.createElement(RouterProvider, { router: router }),
        modulePortals));
};
const HydrateAtoms = ({ children }) => {
    const queryClient = useQueryClient();
    useHydrateAtoms([[queryClientAtom, queryClient]]);
    return children;
};
