import { isAxiosError } from "axios";
import { StatusCodes } from "http-status-codes";
import { useAtomValue } from "jotai";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { ceApi } from "@ce/api/ceApi";
import { previousLocationAtom } from "../router";
import { PageHeader } from "./_PageHeader";
import { RequestInfo } from "./_RequestInfo";
import { Toolbar } from "./_Toolbar";
export function NotFound() {
    const { t } = useTranslation();
    const location = useLocation();
    const previousLocation = useAtomValue(previousLocationAtom);
    const [serverStatus, setServerStatus] = useState(null);
    useEffect(() => {
        // Route not found in react router.
        // Check if .NET has this route and if so, do a hard page reload.
        const checkDotNetPageStatus = async () => {
            const url = location.pathname + location.search;
            let dotnetStatus = null;
            try {
                const { status } = await ceApi.head(url, {
                    headers: { "X-Route-Check": 1 },
                });
                dotnetStatus = status;
            }
            catch (error) {
                if (isAxiosError(error)) {
                    dotnetStatus = error.response?.status ?? null;
                }
            }
            if (dotnetStatus !== StatusCodes.NOT_FOUND && previousLocation) {
                // We only want to reload once. When navigating from a react page to a .net page via client side router.
                // On the reloaded .net page, the router will be loaded again and end up in the catch-all route again.
                // This would result in a redirect loop, so we check if we have a previousLocation from the router.
                const previousUrl = previousLocation.pathname + previousLocation.search;
                if (url !== previousUrl) {
                    window.location.reload();
                }
            }
            setServerStatus(dotnetStatus);
        };
        checkDotNetPageStatus();
    }, [location, previousLocation]);
    return (serverStatus === StatusCodes.NOT_FOUND && (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, null),
        React.createElement(Card, null,
            React.createElement(Card.Body, { className: "error-page d-flex flex-column align-items-center" },
                React.createElement("h1", { className: "display-1" }, "404"),
                React.createElement("h2", null, t("NotFoundTitle")),
                React.createElement("p", { className: "lead" }, t("NotFoundDescription")),
                React.createElement(RequestInfo, { showRequestId: false, showLogId: false }),
                React.createElement(Toolbar, null))))));
}
