import { useEffect, useState } from "react";
export const useCurrentLoginMethod = () => {
    const [method, setMethod] = useState(null);
    useEffect(() => {
        const body = document.querySelector("body");
        const loginMethod = body?.getAttribute("data-login-method");
        setMethod(loginMethod);
    }, []);
    return method;
};
