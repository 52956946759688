/* eslint-disable unicorn/prefer-array-some */
import { isAxiosError } from "axios";
import { isEmpty, isObject } from "lodash-es";
import isPlainObject from "lodash-es/isPlainObject";
export function isRecord(value) {
    return isPlainObject(value);
}
export function isApiError(error) {
    return (isRecord(error) &&
        ["StatusCode", "LogId", "Success", "Message", "ValidationErrors"].every((key) => key in error));
}
export function isValidationProblem(error) {
    return (isRecord(error) &&
        ["errors", "type", "title", "status", "detail", "traceId"].every((key) => key in error));
}
export function isApiValidationError(error) {
    return isAxiosError(error) && isApiError(error.response?.data);
}
export function isApiException(error) {
    return (isAxiosError(error) &&
        isApiError(error.response?.data) &&
        Boolean(error.response?.data.Message) &&
        isEmpty(error.response?.data.ValidationErrors));
}
export function isBasicException(error) {
    return isAxiosError(error) && !isObject(error?.response?.data);
}
export function isDefined(value) {
    return value !== null && value !== undefined;
}
