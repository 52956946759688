/* eslint-disable no-console */
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import React, { Component } from "react";
import { Alert } from "react-bootstrap";
/**
 * React Error Boundary component for top level.
 *
 * Note: This component doesn't support translations.
 *
 * @class ErrorBoundary
 * @extends {React.Component<ErrorProps, ErrorState>}
 */
class ErrorBoundary extends Component {
    constructor() {
        super(...arguments);
        this.state = { hasError: false };
    }
    static getDerivedStateFromError() {
        return { hasError: true };
    }
    componentDidCatch(error, errorInfo) {
        console.error("Uncaught error:", error, errorInfo);
    }
    render() {
        return (React.createElement(SentryErrorBoundary, { fallback: this.props.fallback || React.createElement(ErrorBoundaryAlert, null), onError: (error, componentStack) => {
                this.componentDidCatch(error, { componentStack });
            } }, this.props.children));
    }
}
export default ErrorBoundary;
export const ErrorBoundaryAlert = () => {
    return (React.createElement(Alert, { variant: "danger" },
        React.createElement(Alert.Heading, null, "Whoops! Something went wrong"),
        React.createElement("p", null, "It seems something went wrong, our technical team has been notified.")));
};
